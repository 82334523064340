import React from "react";
import Spinner from "react-bootstrap/Spinner";
// const Spinner = React.lazy(() => import("react-bootstrap/Spinner"));
import Style from "./CommonLoader.module.scss";
const CommonLoader = () => {
  return (
    <div className={Style.loader_default}>
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
};

export default CommonLoader;
