import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  cueElementsData: null,
};

export const getCueElements = createAsyncThunk(
  "cueElements/getCueElements",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`courses/cues/${params.cueId}/elements`, {
        headers: { "X-Accept-Learner": params?.leanderId },
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const logCue = createAsyncThunk(
  "cueElements/logCue",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        "learner/audit/cue",
        {
          loggable_id: params.loggable_id,
          status: params.status,
        },
        {
          headers: { "X-Accept-Learner": params?.leanderId },
        }
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const logQuestions = createAsyncThunk(
  "cueElements/logQuestions",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `learner/audit/${params.cueType}`,
        {
          loggable_id: params.loggable_id,
          status: params.status,
          cue_element_order_id: params.cue_element_order_id,
          pool_activity_order_id: params.pool_activity_order_id,
        },
        {
          headers: { "X-Accept-Learner": params?.leanderId },
        }
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const courseSlice = createSlice({
  name: "cueElements",
  initialState,
  reducers: {
    updateCueElementStatus: (state, action) => {
      state.cueElementsData[action.payload].finished = true;
    },
    clearCueElements: (state, action) => {
      state.cueElementsData = null;
    },
  },
  extraReducers: {
    [getCueElements.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getCueElements.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.cueElementsData = action.payload?.data?.data;
    },
    [getCueElements.rejected]: (state, action) => {
      state.status = "failed";
      state.message = action.payload?.message;
    },
  },
});

export const { updateCueElementStatus, clearCueElements } = courseSlice.actions;

export default courseSlice.reducer;
