import {  createSlice } from "@reduxjs/toolkit";

const initialState = {};


const activeCueSlice = createSlice({
  name: "activeCue",
  initialState,
  reducers: {
    updateActiveCue:(state,action)=>{
      state.activeCue = action.payload;
    },
    FinishesActiveCue:(state)=>{
      state.activeCue.status = 'finished';
    },
    ClearActiveCue:(state)=>{
      state.activeCue.status = '';
    },
  },
  extraReducers: {},
});

export const {updateActiveCue,FinishesActiveCue,ClearActiveCue} = activeCueSlice.actions;

export default activeCueSlice.reducer;
