import React, { useState, useEffect } from "react";
import Style from "./NotificationComponent.module.scss";
import Assets from "../../Layout/Assests";
import { Col, Row, Card, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import HeaderPwa from "../../Header/HeaderPwa";
import { useProfile } from "../../../logic/useProfile";
import { getMinimalisticRelativeTime } from "../../../util/Helpers/Duration";
import { useLocation, useHistory } from "react-router-dom";

function NotificationComponent() {
  // getNotificationsData
  const location = useLocation();

  const [currentUser, setCurrentUser] = useState({});
  const { allProfiles, notifications, getProfiles, readNotifications } =
    useProfile();

  useEffect(() => {
    getProfiles();
  }, []);

  useEffect(() => {
    if (notifications?.length > 0) {
      readNotifications(allProfiles[0]?.id);
    }
  }, [allProfiles]);

  return (
    <React.Fragment>
      <HeaderPwa title="Notifications" location={location} />
      <section className={Style.notification_sec}>
        <Container>
          {notifications?.length > 0 ? (
            <>
              {notifications?.map((item, index) => {
                return (
                  <a>
                    <div className={Style.notification_single}>
                      <div className={Style.notification_single_img}>
                        <i
                          className={`${
                            item.data?.reference_type == "cue"
                              ? "icon-success-o"
                              : "icon-error-o"
                          } 
                          ${
                            item.data?.reference_type == "badge"
                              ? "icon-info-o"
                              : ""
                          } 
                          ${
                            item.data?.reference_type == "pace"
                              ? "icon-info-o"
                              : ""
                          } 
                          ${
                            item.data?.reference_type == "leaderboard"
                              ? "icon-info-o"
                              : ""
                          } 
                          ${
                            item.data?.reference_type == "checkpoint"
                              ? "icon-info-o"
                              : ""
                          } 
                         
                          `}
                        ></i>
                      </div>
                      <div className={Style.notification_single_info}>
                        <h4>
                          {item.data?.title}
                          <span className={Style.notification_single_time}>
                            {getMinimalisticRelativeTime(item.updated_at)} ago
                          </span>
                        </h4>
                        <p>
                          {item.data?.message}
                          {/* <span className={Style.notification_single_link}>
                            Buy new course
                          </span> */}
                        </p>
                      </div>
                    </div>
                  </a>
                );
              })}
            </>
          ) : (
            <div className={`no-data py-5 py-lg-2 text-center`}>
              No new notifications
            </div>
          )}
        </Container>
      </section>
    </React.Fragment>
  );
}

export default NotificationComponent;
