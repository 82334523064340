import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
};

export const logVideo = createAsyncThunk(
  "video/logVideo",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        "learner/audit/video",
        {
          loggable_id: params.loggable_id,
          status: params.status,
          cue_element_order_id: params.cue_element_order_id,
        },
        {
          headers: { "X-Accept-Learner": params?.leanderId },
        }
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const videoSlice = createSlice({
  name: "cueVideo",
  initialState,
  reducers: {},

  extraReducers: {
    [logVideo.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [logVideo.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.logData = action.payload?.data;
    },
    [logVideo.rejected]: (state, action) => {
      state.status = "failed";

      if (action.payload?.data?.length !== 0) {
        state.logData = action.payload?.data;
      }
      state.message = action.payload?.message;
    },
  },
});

export default videoSlice.reducer;
