import { combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authSlice1 from "./slices/authSlice";
import courseSlice from "./slices/courseSlice";
import cueSlice from "./slices/cueSlice";
import cueElementSlice from "./slices/cueElementSlice";
import activitySlice from "./slices/activitySlice";
import flashcardSlice from "./slices/flashcardSlice";
import videoSlice from "./slices/videoSlice";
import profileSlice from "./slices/profileSlice";
import utlitySlice from "./slices/utlitySlice";
import activeCueSlice from "./slices/activeCueSlice";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["loginData", "registerData"],
};

const authSlice = persistReducer(persistConfig, authSlice1);
const rootReducer = combineReducers({
  auth: authSlice,
  course: courseSlice,
  cue: cueSlice,
  cueElement: cueElementSlice,
  activity: activitySlice,
  flashcard: flashcardSlice,
  cueVideo: videoSlice,
  profile: profileSlice,
  utility: utlitySlice,
  activeCue:activeCueSlice

});

export default rootReducer;
