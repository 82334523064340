import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  registerData: null,
  loginData: null,
  userExists: false,
};

export const registerUserThunk = createAsyncThunk(
  "auth/registerUserThunk",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("register", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const loginUserThunk = createAsyncThunk(
  "auth/loginUserThunk",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("auth/login", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const verifyOTPThunk = createAsyncThunk(
  "auth/verifyOTPThunk",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("auth/login/otp", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const verifyRegisterOTPThunk = createAsyncThunk(
  "auth/verifyRegisterOTPThunk",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("register/otp", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const resendRegisterOTPThunk = createAsyncThunk(
  "auth/resendRegisterOTPThunk",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("register/resend-otp", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const verifyAlternateOtp = createAsyncThunk(
  "auth/verifyAlternateOtp",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      var formData = new FormData();
      formData.append("otp", params.otp);
      formData.append("mobile_no", params.mobile_no);
      formData.append("phone_country_code", params.phone_country_code);
      const response = await api.post(
        `user/profiles/${params.profile_Id}/verify-otp`,
        formData
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const verifyAlternateMobile = createAsyncThunk(
  "auth/verifyAlternateMobile",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      var formData = new FormData();
      formData.append("mobile_no", params.mobile_no);
      formData.append("phone_country_code", params.phone_country_code);
      const response = await api.post(
        `user/profiles/${params.profile_Id}/verify-alternate-mobile`,
        formData
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserExists: (state, action) => {
      state.userExists = action?.payload;
    },
    clearErrorMsg: (state, action) => {
      state.error = null;
    },

    logOut: (state, action) => {
      state.status = "idle";
      state.error = null;
      state.message = null;
      state.registerData = null;
      state.loginData = null;
      state.userExists = false;
      localStorage.removeItem("USER_ACCESS_TOKEN");
    },
    updateAuthUserProfileData: (state, action) => {
      const index = current(state.loginData.user.profiles).findIndex(
        (item) => item.id === action.payload.id
      );

      state.loginData.user.profiles[index] = {
        ...state.loginData.user.profiles[index],
        ...action.payload,
      };
    },
  },
  extraReducers: {
    [registerUserThunk.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [registerUserThunk.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.userExists = false;
      state.registerData = action.payload?.data?.data;
    },
    [registerUserThunk.rejected]: (state, action) => {
      state.status = "failed";

      if (action.payload?.data?.length !== 0) {
        state.registerData = action.payload?.data;
      }
      state.message = action.payload?.message;
    },
    [loginUserThunk.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
      state.error = null;
    },
    [loginUserThunk.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.registerData = action.payload?.data?.data;
    },
    [loginUserThunk.rejected]: (state, action) => {
      state.status = "failed";
      state.error = true;
      state.message = action.payload?.message;
    },
    [verifyOTPThunk.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
      state.error = null;
    },
    [verifyOTPThunk.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.loginData = action.payload.data.data;
      localStorage.setItem(
        "USER_ACCESS_TOKEN",
        action.payload?.data?.data?.access_token
      );
    },
    [verifyOTPThunk.rejected]: (state, action) => {
      state.status = "failed";
      state.error = true;
      state.message = action.payload?.message;
    },
    [verifyRegisterOTPThunk.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
      state.error = null;
    },
    [verifyRegisterOTPThunk.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.loginData = action.payload.data.data;
      localStorage.setItem(
        "USER_ACCESS_TOKEN",
        action.payload?.data?.data?.access_token
      );
    },
    [verifyRegisterOTPThunk.rejected]: (state, action) => {
      state.status = "failed";
      state.error = true;
      state.message = action.payload?.message;
    },
  },
});

export const {
  setUserExists,
  clearErrorMsg,
  logOut,
  updateAuthUserProfileData,
} = authSlice.actions;
export default authSlice.reducer;
