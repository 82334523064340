import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getCourse } from "../store/slices/courseSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getAllProfiles,
  getProfileOfUser,
  updateProfilePhoto,
  createUserProfile,
  updateUserProfile,
  updateUserEducation,
  updateUserSocialMedia,
  getLeaderBoard,
  getDashBoard,
  getStreak,
  getCurrentFuelData,
  getFuelHistory,
  getBasicSettings,
  profileClaim,
  verifyCheckOutDetails,
  addToCart,
  verifyPurchaseCompletion,
  checkoutDetails,
  badgesEarned,
  profileFlashCards,
  videoPlaylist,
  getNotifications,
  getPurchaseHistory,
  getLeaderBoardClubType,
  markAsReadNotifications,
  markAsReadNotificationById,
  updateUserProfileData,
  updateUserSocialData,
  userPreference,
  updateProfileClaimState,
  getStoreItems,
  buyNowItems,
  updateStoreData,
  handleClickAccordion,
} from "../store/slices/profileSlice";

import { updateAuthUserProfileData } from "../store/slices/authSlice";
import moment from "moment";

export const useProfile = (claimParams, setClaimParams) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const { courseData } = useSelector((state) => state.course);
  const auth = useSelector((state) => state.auth);
  const [claimFuelProfile, setClaimFuelProfile] = useState({});
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [checkOutDetailsData, setCheckOutDetailsData] = useState({});
  const [badges, setBadges] = useState([]);
  const [flashcardCollections, setFlashcardCollections] = useState([]);
  const [watchedVideoList, setWatchedVideoList] = useState([]);
  const [purchaseHistory, setPurchaseHistory] = useState([]);
  const [clubType, setClubType] = useState([]);
  const [fuelValue, setFuelValue] = useState(0);
  const [claimBadges, setClaimBadges] = useState([]);
  const [showBadgeIndex, SetShowBadgeIndex] = useState(-1);
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [openClaim, setOpenClaim] = useState(false);
  const [isSubmittingCheckOut, setIsSubmittingCheckOut] = useState(false);
  const [isSubmittingEducation, setIsSubmittingEducation] = useState(false);
  const [isSubmittingSocial, setIsSubmittingSocial] = useState(false);
  const [isClubLoading, setIsClubLoading] = useState(false);
  const [isBadgesLoading, setIsBadgesLoading] = useState(false);
  const [isVideoPlaylistLoading, setIsVideoPlaylistLoading] = useState(false);
  const [isPurchaseHistory, setIsPurchaseHistory] = useState(false);
  const [isFlashCardLoading, setIsFlashCardLoading] = useState(false);
  const [showSignUpClaimModal, setShowSignUpClaimModal] = useState(false);
  const [signUpFuelValue, setSignUpFuelValue] = useState(0);
  const [showNotificationPopupIndex, setShowNotificationPopupIndex] =
    useState(-1);
  const [notificationPopupData, setnotificationPopupData] = useState([]);
  const [
    showNotificationDashboardPopupIndex,
    setShowNotificationDashboardPopupIndex,
  ] = useState(-1);
  const [notificationPopupDashboardData, setnotificationPopupDashboardData] =
    useState([]);
  const [openBuyNowConfirmationModal, setOpenBuyNowConfirmationModal] =
    useState(false);
  const [openBuyNowSuccessModal, setOpenBuyNowSuccessModal] = useState(false);
  const [openBuyNowFailureModal, setOpenBuyNowFailureModal] = useState(false);
  const [claimBuyNowBadges, setClaimBuyNowBadges] = useState([]);
  const [showBuyNowBadgeIndex, setShowBuyNowBadgeIndex] = useState(-1);

  const {
    allProfiles,
    userProfile,
    leaderBoard,
    dashboardData,
    streak,
    currentFuel,
    fuelHistory,
    basicSettings,
    notifications,
    status,
  } = useSelector((state) => state.profile);

  useEffect(() => {
    if (showModal == false) {
      if (claimBadges.length != 0) {
        SetShowBadgeIndex(0);
      }
    }
  }, [showModal]);

  useEffect(() => {
    if (openBuyNowSuccessModal == false) {
      if (claimBuyNowBadges?.length != 0) {
        setShowBuyNowBadgeIndex(0);
      }
    }
  }, [openBuyNowSuccessModal]);

  const getBasicSettingsData = () => {
    dispatch(getBasicSettings());
  };

  const onBadgeHide = () => {
    SetShowBadgeIndex(showBadgeIndex + 1);
    dispatch(
      updateProfileClaimState({
        profile_Id: auth?.loginData?.user?.profiles[0]?.id,
      })
    );
  };

  useEffect(() => {
    if (courseData == null) {
      dispatch(
        getCourse({ profileId: auth?.loginData?.user?.profiles[0]?.id })
      );
    }
  }, [courseData]);

  const getStreakData = () => {
    dispatch(getStreak({ profileId: auth?.loginData?.user?.profiles[0]?.id }));
  };

  const getCurrentFuel = () => {
    dispatch(
      getCurrentFuelData({ profileId: auth?.loginData?.user?.profiles[0]?.id })
    );
  };

  const getFaqData = (id) => {
    // dispatch(getFaq({ profile_id: id }));
  };

  const getProfiles = () => {
    dispatch(getAllProfiles());
  };

  const getUserProfile = () => {
    dispatch(
      getProfileOfUser({ profileId: auth?.loginData?.user?.profiles[0]?.id })
    );
  };

  const createUser = () => {
    dispatch(createUserProfile())
      .unwrap()
      .then((value) => {
        getProfiles();
      });
  };

  const updateProfilePhotoData = (id, imgUrl) => {
    let data = {
      profile_Id: id,
      img: imgUrl,
    };
    dispatch(updateProfilePhoto(data))
      .unwrap()
      .then((value) => {
        toast.dark("Profile Picture Updated", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const updateUser = async (data, mode, { setSubmitting, resetForm }) => {
    // setProfileAccordion("1")
    const resultAction = await dispatch(updateUserProfile(data));
    if (updateUserProfile.fulfilled.match(resultAction)) {
      // setEducationAccordion('2')
      toast.dark("Profile Updated Successfully", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch(updateUserProfileData(data));
      dispatch(updateAuthUserProfileData(data));
      dispatch(handleClickAccordion("0"));
      setTimeout(() => {
        dispatch(handleClickAccordion("1"));
      }, 500);
    } else {
      if (resultAction.payload) {
        let err = resultAction.payload.errors;

        Object.keys(data).forEach((key, index) => {
          if (err[key] != undefined) {
            toast.error(err[key][0], {
              position: "bottom-right",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });
      }
    }
  };

  const updateEducation = async (data, user) => {
    dispatch(updateUserProfileData(data));
    data = {
      ...data,
      profile_Id: user.id,
    };
    setIsSubmittingEducation(!isSubmittingEducation);
    const resultAction = await dispatch(updateUserEducation(data));

    if (updateUserEducation.fulfilled.match(resultAction)) {
      toast.dark("Educational Details Updated Successfully", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch(handleClickAccordion("1"));
      setTimeout(() => {
        dispatch(handleClickAccordion("2"));
      }, 500);
    }
  };

  const updateSignUpPreference = async (data) => {
    const resultAction = await dispatch(userPreference(data));
    if (userPreference.fulfilled.match(resultAction)) {
      history.push({
        pathname: "/",
        state: {
          signUpValue: {
            openModal: true,
            signUpFuel: 25,
            type: "initial_signUp",
            title: "Hurray!!",
            message: `Welcome to the Robolab. You just earned yourself 25 gallons.`,
          },
        },
      });
    }
  };

  const updateSocial = async (data, user) => {
    let socialData = {
      social: data,
      profile_Id: user?.id,
    };
    dispatch(updateUserSocialData(socialData));
    const resultAction = await dispatch(
      updateUserSocialMedia({ social: data, profile_Id: user })
    );

    setIsSubmittingSocial(!isSubmittingSocial);
    if (updateUserSocialMedia.fulfilled.match(resultAction)) {
      toast.dark("Social Media Updated Successfully", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch(handleClickAccordion("2"));
    } else {
      if (resultAction.payload) {
        let err = resultAction.payload.errors;
        Object.keys(data).forEach((key, index) => {
          let text1 = "accounts.";
          let newKey = text1.concat(key);
          let newField = newKey.concat(".type_id");
          if (err[newField]) {
            toast.dark(err[newField][0], {
              position: "bottom-right",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });
      }
    }
  };

  const getLeaderBoardData = () => {
    dispatch(
      getLeaderBoard({ profileId: auth?.loginData?.user?.profiles[0]?.id })
    );
  };

  const getDashBoardData = () => {
    dispatch(
      getDashBoard({ profileId: auth?.loginData?.user?.profiles[0]?.id })
    );
  };

  const getProfileClaims = async (slug, id) => {
    const resultAction = await dispatch(
      profileClaim({ slug: slug, profile_Id: id })
    );
    if (profileClaim.fulfilled.match(resultAction)) {
      if (resultAction.payload?.fuels) {
        setShowModal(true);
        setFuelValue(
          resultAction.payload?.fuels ? resultAction.payload?.fuels : 0
        );
      }
      setOpenClaim(true);

      setClaimBadges(resultAction.payload?.badges);
    } else {
      toast.dark(resultAction.payload?.message, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const addToCartItem = async (profile_Id, course_Id) => {
    const resultAction = await dispatch(
      addToCart({ profile_Id: profile_Id, course_Id: course_Id })
    );
    if (addToCart.fulfilled.match(resultAction)) {
      // toast.dark("Course added to cart", {
      //   position: "bottom-right",
      //   autoClose: 2000,
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });
    } else {
      toast.dark("Course Already Purchased", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const getCheckoutDetails = async (profile_Id, course_Id) => {
    let resultAction = await dispatch(
      checkoutDetails({ profile_Id: profile_Id, course_Id: course_Id })
    );
    if (checkoutDetails.fulfilled.match(resultAction)) {
      const { data } = resultAction.payload.data;
      setCheckOutDetailsData(data);
    }
  };

  // payment

  const verifyCheckOut = async (addressDetails, profile, course_id) => {
    setIsSubmittingCheckOut(!isSubmittingCheckOut);
    const resultAction = await dispatch(
      verifyCheckOutDetails({
        billing_address: addressDetails.billing_address,
        shipping_address: addressDetails.shipping_address,
        profile_id: profile?.id,
        course_id: course_id,
      })
    );
    if (verifyCheckOutDetails.fulfilled.match(resultAction)) {
      const { data } = resultAction.payload.data;
      dispatch(displayRazorpay(data, profile, addressDetails.billing_address));
    } else {
      setIsSubmittingCheckOut(false);
      if (resultAction.payload) {
        if (resultAction.payload?.message) {
          toast.dark("Course Already Purchased", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        let err = resultAction.payload.errors;
        if (addressDetails?.billing_address?.is_for_shipping === 0) {
          Object.keys(addressDetails.shipping_address).forEach((key, index) => {
            let text1 = "shipping_address.";
            let newKey = text1.concat(key);
            if (err[newKey] != undefined) {
              toast.error(err[newKey][0], {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          });
        } else {
          Object.keys(addressDetails.billing_address).forEach((key, index) => {
            let text1 = "billing_address.";
            let newKey = text1.concat(key);

            if (err[newKey] != undefined) {
              toast.error(err[newKey][0], {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          });
        }
      }
    }
  };

  const displayRazorpay =
    (checkOutRes, userdata, addressData) => async (dispatch) => {
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );

      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }

      let result = {
        amount: checkOutRes.amount,
        currency: checkOutRes.currency,
        receipt: "Receipt no. 1",
        payment_capture: 1,
        order_id: checkOutRes.id,
      };

      const { amount, order_id, currency } = result;

      const options = {
        key: checkOutRes.razorpay_key,
        amount: amount.toString(),
        currency: currency,
        name: "Inker",
        description: "Testing wallet Transaction ",
        order_id: order_id,
        method: {
          netbanking: "1",
          card: "1",
          upi: "1",
          wallet: "0",
        },
        handler: async function (response) {
          const data = {
            orderCreationId: order_id,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_order_id: response.razorpay_order_id,
            razorpay_signature: response.razorpay_signature,
          };

          verify_completion({
            status: 1,
            razorpayRes: data,
            orderData: checkOutRes,
            profile_Id: userdata.id,
          });
        },
        prefill: {
          name: addressData.name,
          email: addressData.email,
          contact: userdata.mobile_no
            ? userdata.mobile_no
            : auth?.registerData?.mobile,
        },
        theme: {
          color: "#FE7802",
        },
        modal: {
          ondismiss: function () {
            setIsSubmittingCheckOut(false);
          },
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.on("payment.failed", function (response) {
        const data = {
          razorpayPaymentId: response.metadata.payment_id,
          razorpayOrderId: response.metadata.order_id,
        };
        dispatch(
          verify_completion({
            status: 2,
            razorpayRes: data,
            orderData: checkOutRes,
            profile_Id: userdata.id,
          })
        );
      });
      paymentObject.open();
    };

  const verify_completion = async (
    status,
    razorpayRes,
    orderData,
    profile_Id
  ) => {
    const resultAction = await dispatch(
      verifyPurchaseCompletion(status, razorpayRes, orderData, profile_Id)
    );
    if (verifyPurchaseCompletion.fulfilled.match(resultAction)) {
      const { data } = resultAction.payload;
      if (data.success) {
        setOpenSuccessModal(true);
      }
    } else {
      toast.error("Razorpay error", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    setIsSubmittingCheckOut(false);
  };

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  //
  const getBadgesEarned = (id) => {
    setIsBadgesLoading(true);
    dispatch(badgesEarned({ profile_Id: id }))
      .unwrap()
      .then((value) => {
        setBadges(value);
        setIsBadgesLoading(false);
      });
  };

  const getProfileFlashCards = (searchText) => {
    // setIsFlashCardLoading(true);
    dispatch(
      profileFlashCards({
        profile_Id: auth?.loginData?.user?.profiles[0]?.id,
        searchText: searchText,
      })
    )
      .unwrap()
      .then((value) => {
        setFlashcardCollections(value.data);
      });
  };

  const getVideoPlaylist = (searchText) => {
    setIsVideoPlaylistLoading(true);
    dispatch(
      videoPlaylist({
        profile_Id: auth?.loginData?.user?.profiles[0]?.id,
        searchText: searchText,
      })
    )
      .unwrap()
      .then((value) => {
        setWatchedVideoList(value.data);
        setIsVideoPlaylistLoading(false);
      });
  };

  const getPurchaseHistoryData = () => {
    setIsPurchaseHistory(true);
    dispatch(
      getPurchaseHistory({
        profile_Id: auth?.loginData?.user?.profiles[0]?.id,
      })
    )
      .unwrap()
      .then((value) => {
        setPurchaseHistory(value);
        setIsPurchaseHistory(false);
      });
  };

  const getLeaderBoardClubTypeData = () => {
    setIsClubLoading(true);
    dispatch(getLeaderBoardClubType())
      .unwrap()
      .then((value) => {
        setClubType(value);
        setIsClubLoading(false);
      });
  };

  const getNotificationsData = (id) => {
    let today = moment();

    dispatch(
      getNotifications({ profile_Id: auth?.loginData?.user?.profiles[0]?.id })
    )
      .unwrap()
      .then((value) => {
        let filteredData = [];
        let filteredDataDashboard = [];

        value?.data?.data.map((item, i) => {
          if (item?.data?.type && item?.data?.type !== "reset-notice") {
            let notificationDate = moment(item?.created_at).format(
              "YYYY-MM-DD"
            );
            if (notificationDate == today.format("YYYY-MM-DD")) {
              filteredData = [...filteredData, item];
              setShowNotificationPopupIndex(0);
            }
          }
          if (item?.data?.type && item?.data?.type == "reset-notice") {
            filteredDataDashboard = [...filteredDataDashboard, item];
            setShowNotificationDashboardPopupIndex(0);
          }
        });
        setnotificationPopupDashboardData(filteredDataDashboard);
        setnotificationPopupData(filteredData);
      });
  };

  const onHideNotifcationPopup = () => {
    setShowNotificationPopupIndex(showNotificationPopupIndex + 1);
  };

  const readNotifications = (id) => {
    dispatch(markAsReadNotifications({ profile_Id: id }));
  };

  const readNotificationById = (id) => {
    dispatch(
      markAsReadNotificationById({
        profile_Id: auth?.loginData?.user?.profiles[0]?.id,
        notification_id: id,
      })
    );
  };

  const fetchStoreItems = () => {
    dispatch(
      getStoreItems({ profile_Id: auth?.loginData?.user?.profiles[0]?.id })
    );
  };

  const buyNowStoreItems = async (data) => {
    const resultAction = await dispatch(
      buyNowItems({
        profile_Id: auth?.loginData?.user?.profiles[0]?.id,
        item_id: data.id,
      })
    );
    if (buyNowItems.fulfilled.match(resultAction)) {
      const { data } = resultAction.payload;
      setOpenBuyNowConfirmationModal(false);
      setOpenBuyNowSuccessModal(true);
      dispatch(updateStoreData(data?.data));
      setClaimBuyNowBadges(data?.badges);
    } else {
      setOpenBuyNowConfirmationModal(false);
      setOpenBuyNowFailureModal(true);
    }
  };

  return {
    isClubLoading,
    isBadgesLoading,
    isVideoPlaylistLoading,
    errorMessage,
    fuelValue,
    claimBadges,
    showModal,
    showBadgeIndex,
    clubType,
    courseData,
    auth,
    allProfiles,
    userProfile,
    leaderBoard,
    dashboardData,
    streak,
    currentFuel,
    fuelHistory,
    basicSettings,
    claimParams,
    claimFuelProfile,
    openClaim,
    isSubmittingCheckOut,
    openSuccessModal,
    checkOutDetailsData,
    badges,
    flashcardCollections,
    watchedVideoList,
    notifications,
    purchaseHistory,
    isPurchaseHistory,
    isFlashCardLoading,
    onBadgeHide,
    setShowModal,
    getPurchaseHistoryData,
    setOpenSuccessModal,
    setIsSubmittingCheckOut,
    setOpenClaim,
    getLeaderBoardData,
    getProfiles,
    getUserProfile,
    updateProfilePhotoData,
    updateUser,
    createUser,
    updateEducation,
    updateSocial,
    getDashBoardData,
    getStreakData,
    getCurrentFuel,
    getProfileClaims,
    verifyCheckOut,
    addToCartItem,
    getCheckoutDetails,
    getBadgesEarned,
    getProfileFlashCards,
    getVideoPlaylist,
    getNotificationsData,
    getFaqData,
    getLeaderBoardClubTypeData,
    getBasicSettingsData,
    readNotifications,
    isSubmittingEducation,
    setIsSubmittingEducation,
    setIsSubmittingSocial,
    isSubmittingSocial,
    updateSignUpPreference,
    showSignUpClaimModal,
    setShowSignUpClaimModal,
    signUpFuelValue,
    readNotificationById,
    showNotificationPopupIndex,
    setShowNotificationPopupIndex,
    notificationPopupData,
    setnotificationPopupData,
    onHideNotifcationPopup,
    showNotificationDashboardPopupIndex,
    notificationPopupDashboardData,
    setShowNotificationDashboardPopupIndex,
    fetchStoreItems,
    buyNowStoreItems,
    openBuyNowConfirmationModal,
    setOpenBuyNowConfirmationModal,
    openBuyNowSuccessModal,
    setOpenBuyNowSuccessModal,
    claimBuyNowBadges,
    showBuyNowBadgeIndex,
    setShowBuyNowBadgeIndex,
    openBuyNowFailureModal,
    setOpenBuyNowFailureModal,
    status,
  };
};
