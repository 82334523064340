import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  cueStatus: "idle",
  currentCueStatus: "idle",
  error: null,
  message: null,
  cueData: null,
  currentActiveCuePage: "",
};

export const getCue = createAsyncThunk(
  "cue/getCue",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `courses/${params.course_id}/modules/cues?page=${params.pageNumber}`,
        { headers: { "X-Accept-Profile": params?.profileId } }
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCurrentCue = createAsyncThunk(
  "cue/getCurrentCue",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `learner/${params.leanderId}/course/modules/cues/currentCue`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const postScecretKey = createAsyncThunk(
  "cue/postScecretKey",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `user/profiles/${params.profileId}/check-secret-key`,
        {
          secret_key: params.secret_key,
        }
      );
      return response;
    } catch (error) {
      // return error;
      return rejectWithValue(error.response);
    }
  }
);

export const getCueCompletionPoint = createAsyncThunk(
  "cue/getCueCompletionPoint",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `learner/${params.leanderId}/course/modules/cues/latest-complete-cue-points`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const cueSlice = createSlice({
  name: "cue",
  initialState,
  reducers: {
    updateCueStatus: (state, action) => {
      state.cueData.data[action.payload.activeCue.moduleIndex].cues[
        action.payload.activeCue.cueIndex
      ].has_completed = true;
      if (
        action.payload.activeCue.moduleIndex != 0 &&
        action.payload.activeCue.cueIndex != 0
      ) {
        if (action.payload.activeCue.cueIndex != 0) {
          state.cueData.data[action.payload.activeCue.moduleIndex].cues[
            action.payload.activeCue.cueIndex - 1
          ].is_current = false;
        } else {
          state.cueData.data[action.payload.activeCue.moduleIndex - 1].cues[
            state.cueData?.data[
              action.payload.activeCue.moduleIndex - 1
            ].cues.length
          ].is_current = false;
        }
      }
      if (
        state.cueData.data[action.payload.activeCue.moduleIndex].cues.length ==
        action.payload.activeCue.cueIndex + 1
      ) {
        state.cueData.data[
          action.payload.activeCue.moduleIndex
        ].has_completed = true;
      }
      state.cueData.data[action.payload.activeCue.moduleIndex].cues[
        action.payload.activeCue.cueIndex
      ].is_current = true;
    },
  },
  extraReducers: {
    [getCue.pending]: (state, action) => {
      state.cueStatus = "pending";
      state.message = null;
    },
    [getCue.fulfilled]: (state, action) => {
      state.cueStatus = "fulfilled";
      if (state.cueData == null) {
        state.cueData = action.payload?.data?.data;
      } else if (
        state.cueData?.current_page < state.cueData?.last_page &&
        action.payload?.data?.data?.current_page !== 1
      ) {
        state.cueData = {
          ...state.cueData,
          data: [...state.cueData.data, ...action.payload?.data?.data?.data],
          current_page: action.payload?.data?.data?.current_page,
        };
      }
    },
    [getCue.rejected]: (state, action) => {
      state.cueStatus = "rejected";

      if (action.payload?.data.length !== 0) {
        state.cueData = action.payload?.data;
      }
      state.message = action.payload?.message;
    },
    //getCurrcue

    [getCurrentCue.pending]: (state, action) => {
      state.currentCueStatus = "pending";
      state.message = null;
    },
    [getCurrentCue.fulfilled]: (state, action) => {
      state.currentCueStatus = "fulfilled";
      state.currentCue = action.payload?.data?.data?.current_cue;
      state.currentActiveCuePage =
        action.payload?.data?.data?.currentPageNumber;
    },
    [getCurrentCue.rejected]: (state, action) => {
      state.currentCueStatus = "rejected";

      if (action.payload?.data?.length !== 0) {
        state.currentCue = action.payload?.data?.data?.current_cue;
        state.currentActiveCuePage =
          action.payload?.data?.data?.currentPageNumber;
      }
      state.message = action.payload?.message;
    },
  },
});

export const { updateCueStatus } = cueSlice.actions;

export default cueSlice.reducer;
