import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  courseData: null,
  resumeDuration: "0.00",
};

export const countryList = createAsyncThunk(
  "utility/countryList",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("user/countries");
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const stateList = createAsyncThunk(
  "utility/stateList",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `user/states?country_id=${params.countryId}`
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const districtList = createAsyncThunk(
  "utility/districtList",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `user/districts?state_id=${params.stateId}`
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getFaq = createAsyncThunk(
  "utility/getFaq",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/faq?search=${params.searchText}`);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const utilitySlice = createSlice({
  name: "utility",
  initialState,
  reducers: {
    currentVideoResumeDuration: (state, action) => {
      state.resumeDuration = action.payload;
    },
  },
  extraReducers: {
    [getFaq.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getFaq.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.allProfiles = action.payload?.data?.data;
    },
    [getFaq.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});

export const { currentVideoResumeDuration } = utilitySlice.actions;
export default utilitySlice.reducer;
