import React, { useState, useEffect } from "react";
import Style from "./HeaderPwa.module.scss";
import Assets from "../../Layout/Assests";
import { Link } from "react-router-dom";
import { useLocation, useHistory } from "react-router-dom";
import { useProfile } from "../../../logic/useProfile";
function HeaderPwa({ title, className, menu, location }) {
  const history = useHistory();

  const [currentUser, setCurrentUser] = useState({});
  const { allProfiles } = useProfile();
  useEffect(() => {
    setCurrentUser(allProfiles?.[0]);
  }, [allProfiles]);

  const gotoProfile = () => {
    localStorage.setItem("userRoute", "details");
    history.push("/profile");
  };

  const handleRoute = () => {
    if (location?.state?.from == "home") {
      history.push("/");
    } else {
      history.goBack();
    }
  };

  return (
    <React.Fragment>
      <div
        className={`${Style.header_mobile} 
     
      `}
      >
        <a onClick={() => handleRoute()}>
          <button className="btn-back">
            <i className="icon-arrow-left-thick"></i>
          </button>
        </a>
        <h3>{title && title.toLowerCase()}</h3>
        {menu && (
          <Link to="/profile" className={`${Style.nav_round} `}>
            <span className={Style.nav_round_profile}>
              {" "}
              {currentUser?.profile_photo_full_url ? (
                <img
                  src={currentUser?.profile_photo_full_url}
                  alt={currentUser?.name}
                  className={`${Style.profileImg} w-100`}
                />
              ) : (
                currentUser?.name?.toUpperCase().charAt(0)
              )}
              {/* {currentUser?.name?.toUpperCase().charAt(0)} */}
            </span>
          </Link>
          // ${Style.active}
        )}
        {history.location.pathname == "/checkout" ? (
          <a onClick={() => gotoProfile()} className={`${Style.nav_round} `}>
            <span className={Style.nav_round_profile}>
              {currentUser?.profile_photo_full_url ? (
                <img
                  src={currentUser?.profile_photo_full_url}
                  alt={currentUser?.name}
                  className={`${Style.profileImg} w-100`}
                />
              ) : (
                currentUser?.name?.toUpperCase().charAt(0)
              )}
            </span>
          </a>
        ) : (
          ""
        )}
      </div>
    </React.Fragment>
  );
}

export default HeaderPwa;
