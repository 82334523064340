import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "../styles/common.scss";
import CommonLoader from "../util/CommonLoader";
import NotificationComponent from "./Dashboard/NotificationComponent";
import Leaderboard from "./Dashboard/Leaderboard";
import ScrollToTop from "./ScrollToTop";

const PrivateRoute = lazy(() => import("../util/AuthChecker"));
const AuthorizedUser = lazy(() => import("../util/AuthorizedUser"));
const AuthCompletedUser = lazy(() => import("../util/AuthCompletedUser"));

const SignupPage = lazy(() => import("../pages/SignUp/SignUp"));
const OtpPage = lazy(() => import("../pages/SignUp/Otp"));
const SignupCompletePage = lazy(() => import("../pages/SignUp/SignUpComplete"));
const SignupPreferencePage = lazy(() =>
  import("../pages/SignUp/SignUpPreference")
);
const LoginPage = lazy(() => import("../pages/Login"));
const Introduction = lazy(() => import("../pages/Introduction"));
const Questions = lazy(() => import("../pages/Questions"));
const CueVideoPage = lazy(() => import("../pages/CueVideo"));
const Map = lazy(() => import("../pages/Map"));
const FlashCardPage = React.lazy(() => import("../pages/FlashCards"));
const CheckoutPage = React.lazy(() => import("../pages/Checkout"));
const BadgeCollectionPage = React.lazy(() =>
  import("../pages/BadgeCollections")
);
const StorePage = React.lazy(() => import("../pages/Store"));
const FlashCardCollectionPage = React.lazy(() =>
  import("../pages/FlashCardCollectionPage")
);
const PurchaseHistoryPage = React.lazy(() =>
  import("../pages/PurchaseHistory")
);
const DashboardPage = React.lazy(() => import("../pages/Dashboard"));
const VideoPlaylistPage = React.lazy(() => import("../pages/VideoPlaylist"));
const ProfilePage = React.lazy(() => import("../pages/Profile"));
const NotFoundPage = React.lazy(() => import("../pages/404"));
const HelpCentrePage = React.lazy(() => import("../pages/HelpCentre"));
const AdditionalResource = React.lazy(() =>
  import("../pages/AdditionalResource")
);
const ReadersCorner = React.lazy(() => import("../pages/ReadersCorner"));
const MapPage = React.lazy(() => import("../pages/Map"));

function App() {
  return (
    <>
      <Router>
        <div>
          <Suspense
            fallback={
              <div>
                <CommonLoader />
              </div>
            }
          >
            <ScrollToTop />
            <Switch>
              <AuthorizedUser exact path="/login" component={LoginPage} />
              <AuthorizedUser exact path="/signup" component={SignupPage} />
              <AuthorizedUser exact path="/otp" component={OtpPage} />

              <AuthCompletedUser
                exact
                path="/signupcomplete"
                component={SignupCompletePage}
              />
              <AuthCompletedUser
                exact
                path="/preference"
                component={SignupPreferencePage}
              />
              <AuthCompletedUser
                exact
                path="/introduction"
                component={Introduction}
              />
              <PrivateRoute exact path="/" component={Map} />

              <PrivateRoute
                exact
                path="/questions"
                component={Questions}
              ></PrivateRoute>
              <PrivateRoute
                exact
                path="/cuevideo"
                component={CueVideoPage}
              ></PrivateRoute>

              <PrivateRoute
                exact
                path="/flashcard"
                component={FlashCardPage}
              ></PrivateRoute>
              <PrivateRoute
                exact
                path="/checkout"
                component={CheckoutPage}
              ></PrivateRoute>
              <PrivateRoute
                exact
                path="/purchasehistory"
                component={PurchaseHistoryPage}
              ></PrivateRoute>

              <PrivateRoute
                exact
                path="/dashboard"
                component={DashboardPage}
              ></PrivateRoute>
              <PrivateRoute
                exact
                path="/notification"
                component={NotificationComponent}
              ></PrivateRoute>
              <PrivateRoute
                exact
                path="/leaderboard"
                component={Leaderboard}
              ></PrivateRoute>

              <PrivateRoute
                exact
                path="/flashcardcollection"
                component={FlashCardCollectionPage}
              ></PrivateRoute>
              <PrivateRoute
                exact
                path="/badgecollection"
                component={BadgeCollectionPage}
              ></PrivateRoute>

              <PrivateRoute
                exact
                path="/videoplaylist"
                component={VideoPlaylistPage}
              ></PrivateRoute>
              <PrivateRoute
                exact
                path="/profile"
                component={ProfilePage}
              ></PrivateRoute>
              <PrivateRoute
                exact
                path="/help"
                component={HelpCentrePage}
              ></PrivateRoute>
              <PrivateRoute
                exact
                path="/additionalresource"
                component={AdditionalResource}
              ></PrivateRoute>
              <PrivateRoute
                exact
                path="/readers-corner"
                component={ReadersCorner}
              ></PrivateRoute>
              <PrivateRoute
                exact
                path="/map"
                component={MapPage}
              ></PrivateRoute>
              <PrivateRoute
                exact
                path="/store"
                component={StorePage}
              ></PrivateRoute>
              <PrivateRoute component={NotFoundPage}></PrivateRoute>
            </Switch>
          </Suspense>
        </div>
      </Router>
    </>
  );
}

export default App;
